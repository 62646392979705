import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Menu Edit Component
const MenuEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({ id: '', title: '', price: '', image: '', status: '', orgId: '', lat: '', lng: '', menu_config: '', category_id: '', description: '', description_en: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/menu/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/menu/`+id, item);
        // Redirect or show success message
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Edit Menu</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">title</label>
                        <input type="text" name="title" value={item.title} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">price</label>
                        <input type="text" name="price" value={item.price} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">image</label>
                        <input type="text" name="image" value={item.image} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">status</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">orgId</label>
                        <input type="text" name="orgId" value={item.orgId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">lat</label>
                        <input type="text" name="lat" value={item.lat} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">lng</label>
                        <input type="text" name="lng" value={item.lng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">menu_config</label>
                        <input type="text" name="menu_config" value={item.menu_config} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">category_id</label>
                        <input type="text" name="category_id" value={item.category_id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">description</label>
                        <input type="text" name="description" value={item.description} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">description_en</label>
                        <input type="text" name="description_en" value={item.description_en} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default MenuEdit;
