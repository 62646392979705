import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Service Edit Component
const ServiceEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({ id: '', country: '', name: '', image: '', duration: '', desc: '', eventDate: '', cost: '', statuse: '', location: '', late: '', lng: '', categoryId: '', userId: '', bundleId: '', createdAt: '', maxBookings: '', payments: '', minPersons: '', countBookings: '', totalPersons: '', city: '', price: '', address: '', descAddress: '', lnk: '', meta: '', startTime: '', isBooked: '', desc_en: '', name_en: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/service/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/service/${id}`, item);
        // Redirect or show success message
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل الخدمة</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">رقم الخدمة</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الدولة</label>
                        <input type="text" name="country" value={item.country} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الاسم</label>
                        <input type="text" name="name" value={item.name} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الصورة</label>
                        <input type="text" name="image" value={item.image} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المدة</label>
                        <input type="text" name="duration" value={item.duration} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف</label>
                        <input type="text" name="desc" value={item.desc} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ الحدث</label>
                        <input type="text" name="eventDate" value={item.eventDate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">التكلفة</label>
                        <input type="text" name="cost" value={item.cost} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <input type="text" name="statuse" value={item.statuse} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الموقع</label>
                        <input type="text" name="location" value={item.location} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط العرض</label>
                        <input type="text" name="late" value={item.late} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط الطول</label>
                        <input type="text" name="lng" value={item.lng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف الفئة</label>
                        <input type="text" name="categoryId" value={item.categoryId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المستخدم</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف الحزمة</label>
                        <input type="text" name="bundleId" value={item.bundleId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ الإنشاء</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">أقصى عدد للحجوزات</label>
                        <input type="text" name="maxBookings" value={item.maxBookings} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المدفوعات</label>
                        <input type="text" name="payments" value={item.payments} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحد الأدنى للأشخاص</label>
                        <input type="text" name="minPersons" value={item.minPersons} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">عدد الحجوزات</label>
                        <input type="text" name="countBookings" value={item.countBookings} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">إجمالي الأشخاص</label>
                        <input type="text" name="totalPersons" value={item.totalPersons} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المدينة</label>
                        <input type="text" name="city" value={item.city} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">السعر</label>
                        <input type="text" name="price" value={item.price} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان</label>
                        <input type="text" name="address" value={item.address} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">وصف العنوان</label>
                        <input type="text" name="descAddress" value={item.descAddress} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الرابط</label>
                        <input type="text" name="lnk" value={item.lnk} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">بيانات تعريفية</label>
                        <input type="text" name="meta" value={item.meta} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">وقت البدء</label>
                        <input type="text" name="startTime" value={item.startTime} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">هل تم الحجز؟</label>
                        <input type="text" name="isBooked" value={item.isBooked} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف بالإنجليزية</label>
                        <input type="text" name="desc_en" value={item.desc_en} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الاسم بالإنجليزية</label>
                        <input type="text" name="name_en" value={item.name_en} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ServiceEdit;