import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Service Detail Component
const ServiceDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/service/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل الخدمة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم الخدمة</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الدولة</label>
                            <input type="text" value={item.country} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الاسم</label>
                            <input type="text" value={item.name} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الصورة</label>
                            <input type="text" value={item.image} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المدة</label>
                            <input type="text" value={item.duration} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الوصف</label>
                            <input type="text" value={item.desc} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ الحدث</label>
                            <input type="text" value={item.eventDate} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">التكلفة</label>
                            <input type="text" value={item.cost} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الحالة</label>
                            <input type="text" value={item.statuse} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الموقع</label>
                            <input type="text" value={item.location} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">خط العرض</label>
                            <input type="text" value={item.late} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">خط الطول</label>
                            <input type="text" value={item.lng} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف الفئة</label>
                            <input type="text" value={item.categoryId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف المستخدم</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف الحزمة</label>
                            <input type="text" value={item.bundleId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ الإنشاء</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">أقصى عدد للحجوزات</label>
                            <input type="text" value={item.maxBookings} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المدفوعات</label>
                            <input type="text" value={item.payments} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الحد الأدنى للأشخاص</label>
                            <input type="text" value={item.minPersons} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">عدد الحجوزات</label>
                            <input type="text" value={item.countBookings} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">إجمالي الأشخاص</label>
                            <input type="text" value={item.totalPersons} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المدينة</label>
                            <input type="text" value={item.city} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">السعر</label>
                            <input type="text" value={item.price} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">العنوان</label>
                            <input type="text" value={item.address} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">وصف العنوان</label>
                            <input type="text" value={item.descAddress} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الرابط</label>
                            <input type="text" value={item.lnk} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">بيانات تعريفية</label>
                            <input type="text" value={item.meta} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">وقت البدء</label>
                            <input type="text" value={item.startTime} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">هل تم الحجز؟</label>
                            <input type="text" value={item.isBooked} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الوصف بالإنجليزية</label>
                            <input type="text" value={item.desc_en} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الاسم بالإنجليزية</label>
                            <input type="text" value={item.name_en} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetail;