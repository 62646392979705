import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Menu Detail Component
const MenuDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(`${baseurl}api/menu/${id}`);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4 text-center">تفاصيل القائمة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">العنوان</label>
                            <input type="text" value={item.title} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">السعر</label>
                            <input type="text" value={item.price} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الصورة</label>
                            <input type="text" value={item.image} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الحالة</label>
                            <input type="text" value={item.status} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">orgId</label>
                            <input type="text" value={item.orgId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">خط العرض</label>
                            <input type="text" value={item.lat} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">خط الطول</label>
                            <input type="text" value={item.lng} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تكوين القائمة</label>
                            <input type="text" value={item.menu_config} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">فئة رقم</label>
                            <input type="text" value={item.category_id} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الوصف</label>
                            <input type="text" value={item.description} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الوصف بالإنجليزية</label>
                            <input type="text" value={item.description_en} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuDetail;


