
import './style.css'
import { baseurl, successNotify } from '../../constants';
import Services from './Services';
import { useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";

const EditProvider = () => {
  const location = useLocation();
  const row = location.state.row;
  const [loader, showLoader, hideLoader] = useLoader(); 
  const navigateTo = useNavigate()


  // State to hold form values
  const [user, setUser] = useState({
    fullName: row.fullName,
    userName: row.userName,
    city: row.city,
    aboutText: row.aboutText,
    bank: row.bank,
    ibanNumber: row.ibanNumber,
    email: row.email,
    balance: row.balance,
    profileImage: row.profileImage,
    banarImage: row.banarImage, // Adding banner image state
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Upload image function
  const uploadImage = async (file) => {
    const formdata = new FormData();
    formdata.append("image", file);
    try {
      const response = await fetch(baseurl + "image/upload", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      return json.image;
    } catch (error) {
      console.error(error);
    }
  };

  // Handle image upload
  const handleImageUpload = async (event, type) => {
    console.log("image")

    const file = event.target.files[0];
    if (file) {
      const imageName = await uploadImage(file);
      if (type === 'profile') {
        setUser({ ...user, profileImage: imageName });
      } else if (type === 'banner') {
        setUser({ ...user, banarImage: imageName });
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader()

      const response = await axios.put(`${baseurl}provider/${row.id}`, user);
      console.log(response.data);
      hideLoader()
      successNotify()
      navigateTo("/admin/providers");
  };

  return (
    <div className="">
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">مزودي الخدمة</div>
        <div className="ps-3">
          <nav aria-label="a">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">تعديل</li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">تعديل المزود</h6>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-6">
                    <div className="col-12">
                      <label className="form-label">الاسم </label>
                      <input 
                        autoComplete="off" 
                        name="fullName" 
                        value={user.fullName} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-12">
                      <label className="form-label">رقم الهاتف </label>
                      <input 
                        autoComplete="off" 
                        name="userName" 
                        value={user.userName} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-12 my-3">
                      <label className="form-label">المدينة </label>
                      <input 
                        autoComplete="off" 
                        name="city" 
                        value={user.city} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-12 my-3">
                      <label className="form-label">نبذة عن </label>
                      <input 
                        autoComplete="off" 
                        name="aboutText" 
                        value={user.aboutText} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>

                    <div className="col-6 text-center">
                      <label htmlFor="ProfileImage" className="form-label">صورة البانر </label>
                      <div className="text-center">
                      <img 
                      style={{ width: '100%', height: 'auto' }} 
                      src={`${baseurl}uploads/${user.banarImage}`} 
                      className="rounded float-center" 
                      alt="Banner" 
                    />
                    <input 
                      type="file" 
                      accept="image/*" 
                      onChange={(e) => handleImageUpload(e, 'banner')} 
                    />
                      </div>
                    </div>

                  </div>
                  
                  <div className='col-6'>
                    <div className="col-12 my-3">
                      <label className="form-label">البنك </label>
                      <input 
                        autoComplete="off" 
                        name="bank" 
                        value={user.bank} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-12 my-3">
                      <label className="form-label">الايبان </label>
                      <input 
                        autoComplete="off" 
                        name="ibanNumber" 
                        value={user.ibanNumber} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-12 my-3">
                      <label className="form-label">الايميل </label>
                      <input 
                        autoComplete="off" 
                        name="email" 
                        value={user.email} 
                        onChange={handleChange} 
                        type="text" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-12 my-3">
                      <label className="form-label">الرصيد </label>
                      <input 
                        autoComplete="off" 
                        name="balance" 
                        value={user.balance} 
                        onChange={handleChange} 
                        type="number" 
                        className="form-control" 
                      />
                      <span className="text-danger" />
                    </div>
                    <div className="col-6 text-center">
                      <label htmlFor="ProfileImage" className="form-label">صورة البروفايل </label>
                      <div className="text-center">
                        <img 
                          style={{ width: '10vw', height: 'auto' }} 
                          src={`${baseurl}uploads/${user.profileImage}`} 
                          className="rounded float-center" 
                          alt="Profile" 
                        />
                        <input 
                          type="file" 
                          accept="image/*" 
                          onChange={(e) => handleImageUpload(e, 'profile')} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">تحديث</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProvider;
